<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route ? `${page_route}.detalle` : 'home',
              params: { id: rha_id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${!loading ? `(${data_table.length})` : ''}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                :color="survey_loading ? '' : survey.id ? 'brown' : 'warning'"
                @click="formAddEditSurvey"
                :loading="survey_loading"
              >
                <v-icon v-text="'mdi-file-table'" />
              </v-btn>
            </template>
            <span v-text="`Encuesta ${survey.id ? '' : ' No Registrada'}`" />
          </v-tooltip>

          <BtnCircle
            v-if="login.permissions.rha_visits_surveys.create"
            tLabel="Agregar"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :click="formAdd"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="rha_id" />
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTROS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'Fecha de visita'" />
                      <th v-text="'Comentario'" />
                      <th v-text="'Acción'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in data_table" :key="i">
                      <td
                        class="font-weight-bold"
                        v-text="data_table.length - i"
                      />
                      <td
                        v-text="
                          `${item.visit_survey_date} ${item.visit_survey_time}`
                        "
                      />
                      <td
                        class="text-description"
                        v-text="
                          item.visit_survey.length > 300
                            ? `${item.visit_survey.substr(0, 300)}...`
                            : item.visit_survey
                        "
                      />
                      <td width="140px">
                        <v-tooltip
                          left
                          v-if="login.permissions.rha_visits_surveys.read"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="secondary"
                              dark
                              @click="formEditView(item.id, false)"
                            >
                              <v-icon v-text="'mdi-eye'" />
                            </v-btn>
                          </template>
                          <span v-text="'Ver'" />
                        </v-tooltip>
                        <v-tooltip
                          left
                          v-if="login.permissions.rha_visits_surveys.update"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="info"
                              dark
                              @click="formEditView(item.id, true)"
                            >
                              <v-icon v-text="'mdi-pencil'" />
                            </v-btn>
                          </template>
                          <span v-text="'Editar'" />
                        </v-tooltip>
                        <v-tooltip
                          left
                          v-if="login.permissions.rha_visits_surveys.delete"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="mr-1"
                              v-on="on"
                              fab
                              x-small
                              color="error"
                              dark
                              @click="desactivate(item.id)"
                            >
                              <v-icon v-text="'mdi-delete'" />
                            </v-btn>
                          </template>
                          <span v-text="'Eliminar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- Dialog STOREUPDATE start -->
    <v-dialog v-model="form_dialog" scrollable persistent max-width="1200px">
      <v-card
        tile
        :loading="loading ? 'grey darken-2' : false"
        :disabled="loading"
      >
        <v-toolbar dark>
          <v-toolbar-title v-text="data.id ? 'Editar' : 'Agregar'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="formDialogClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="form_dialog">
          <v-form v-on:submit.prevent ref="form_submit" lazy-validation>
            <v-row>
              <v-col cols="12" class="mt-3">
                <v-card class="mx-auto">
                  <v-card-title>
                    <h2 class="text-caption" v-text="'VISITA'" />
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="6">
                        <DatePicker
                          label="Fecha*"
                          :model.sync="data.visit_survey_date"
                          :rules="rules.required"
                          clean
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="data.visit_survey_time"
                          :rules="rules.required"
                          label="Hora*"
                          type="time"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="data.visit_survey"
                          label="Comentario*"
                          :rules="rules.required"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  :color="data.id ? 'info' : 'success'"
                  @click="formSubmit"
                >
                  <v-icon v-text="`mdi-${data.id ? 'pencil' : 'plus'}`" left />
                  Aceptar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog STOREUPDATE end -->
    <!-- Dialog VIEW start -->
    <v-dialog v-model="view_dialog" scrollable persistent max-width="1200px">
      <v-card tile>
        <v-toolbar dark>
          <v-toolbar-title v-text="'Ver'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="view_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="view_dialog">
          <v-row>
            <v-col cols="12" class="mt-3">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'VISITA'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="12" md="6">
                      <ViewData label="Fecha" :value="data.visit_survey_date" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <ViewData label="Hora" :value="data.visit_survey_time" />
                    </v-col>
                    <v-col cols="12">
                      <ViewData
                        label="Comentario"
                        :value="data.visit_survey"
                        class="text-description"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog VIEW end -->
    <!-- vars -->
    <!-- Dialog SURVEY start -->
    <v-dialog v-model="survey_dialog" scrollable persistent max-width="1200px">
      <v-card :loading="loading ? 'grey darken-2' : false" :disabled="loading">
        <v-toolbar dark>
          <v-toolbar-title v-text="survey.id ? 'Editar' : 'Agregar'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="formDialogCloseSurvey">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="survey_dialog">
          <v-row>
            <v-col cols="12" class="mt-3" />
            <v-col
              cols="12"
              v-if="
                !survey.id || (survey.id && survey.survey_stadistic_id === 6)
              "
            >
              <v-card class="mx-auto">
                <v-toolbar elevation="0">
                  <v-toolbar-title>
                    <h2 class="text-caption" v-text="'ENCUESTA DIGITAL'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>
                <v-divider />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="6">
                      URL:
                      <span id="link">
                        {{ get_server_name() }}/encuesta/{{ id_base64() }}
                      </span>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <v-btn
                        v-if="!survey.id"
                        text
                        color="green"
                        target="_blank"
                        v-text="'Copiar al portapapeles'"
                        @click="copy()"
                      />
                      <v-btn
                        v-if="survey.id && survey.survey_stadistic_id === 6"
                        text
                        color="brown"
                        :href="url_documents + '/rha_surveys/' + survey.url"
                        target="_blank"
                        v-text="'Ver'"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              v-if="
                !survey.id || (survey.id && survey.survey_stadistic_id !== 6)
              "
            >
              <v-card class="mx-auto">
                <v-toolbar elevation="0">
                  <v-toolbar-title>
                    <h2 class="text-caption" v-text="'ENCUESTA FÍSICA'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-btn
                      v-if="!survey.id"
                      text
                      color="orange"
                      :href="url_pdf + '/encuesta/' + rha_id"
                      target="_blank"
                      v-text="'Descargar'"
                    />
                    <v-btn
                      v-if="survey_file"
                      text
                      color="brown"
                      :href="url_documents + '/rha_surveys/' + survey.url"
                      target="_blank"
                      v-text="'Ver'"
                    />
                  </v-toolbar-items>
                </v-toolbar>
                <v-divider />
                <v-card-text>
                  <v-form ref="form_submit_survey" lazy-validation>
                    <v-row dense>
                      <v-col cols="12">
                        <v-autocomplete
                          label="Estadística"
                          v-model="survey.survey_stadistic_id"
                          :rules="rules.required"
                          :items="survey_stadistics"
                          item-value="id"
                          :item-text="(item) => item.survey_stadistic"
                          :loading="survey_stadistics_loading"
                          :disabled="loading"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        v-if="
                          survey.survey_stadistic_id === 1 ||
                          survey.survey_stadistic_id === 6
                        "
                      >
                        <v-autocomplete
                          label="Tipo"
                          v-model="survey.survey_type_id"
                          :rules="rules.required"
                          :items="survey_types"
                          item-value="id"
                          :item-text="(item) => item.survey_type"
                          :loading="survey_types_loading"
                          :disabled="loading"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        v-if="
                          survey.survey_stadistic_id === 1 ||
                          survey.survey_stadistic_id === 6
                        "
                      >
                        <v-file-input
                          v-model="survey.document_file_0"
                          label="Archivo digitalizado (PDF)"
                          accept=".pdf"
                          show-size
                          :rules="rules.fileLmtReq"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              v-if="
                !survey.id || (survey.id && survey.survey_stadistic_id !== 6)
              "
            >
              <v-btn
                block
                dark
                :color="survey.id ? 'brown' : 'warning'"
                @click="formSubmitSurvey"
                :loading="loading"
              >
                <v-icon left v-text="'mdi-file-table'" />
                Aceptar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog SURVEY end -->
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  URL_PDF,
  URL_DOCUMENTS,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import DatePicker from "../../components/DatePicker.vue";
import RhaData from "../../components/RhaData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    DatePicker,
    RhaData,
    FaqDlg,
  },
  data() {
    return {
      page_route: null,
      api: "rha/visit/survey",
      url_documents: URL_DOCUMENTS,
      login: this.$store.getters.getLogin,
      rha_id: this.$route.params.id,
      loading: true,
      rules: rules(),
      data_table: [],
      data: {},
      form_dialog: false,
      view_dialog: false,
      url_pdf: URL_PDF,
      url_api: URL_API,
      survey: {},
      survey_loading: true,
      survey_dialog: false,
      survey_stadistics_loading: false,
      survey_types: [],
      survey_types_loading: false,
      survey_stadistics: [],
      survey_file: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getDataTable() {
      this.loading = true;

      index(this.api, this.login.token, [
        {
          name: "active",
          value: 1,
        },
        {
          name: "id",
          value: this.login.id,
        },
        {
          name: "rha_id",
          value: this.rha_id,
        },
      ]).then((response) => {
        this.data_table = response.data;
        this.loading = false;
      });
    },
    defaultData() {
      this.data = {
        id: null,
        created_by_id: this.login.id,
        updated_by_id: this.login.id,
        rha_id: this.rha_id,
      };
    },
    formAdd() {
      this.defaultData();
      this.form_dialog = true;
    },
    formEditView(id, edit) {
      show(this.api, this.login.token, id).then((response) => {
        this.data = response;

        if (edit) {
          this.form_dialog = true;
        } else {
          this.view_dialog = true;
        }
      });
    },
    formDialogClose() {
      this.form_dialog = false;
      this.defaultData();
      this.$refs.form_submit.resetValidation();
    },
    formSubmit() {
      if (this.$refs.form_submit.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma ${this.data.id ? "editar" : "agregar"} el registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              Axios.post(
                `${URL_API}/rha/visit/survey`,
                this.data,
                headersToken(this.login.token)
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );

                if (response.data.success) {
                  this.formDialogClose();
                  this.getDataTable();
                } else {
                  console.log(response.data.message);
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    desactivate(id) {
      this.$swal
        .fire(msgConfirm("¿Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy(this.api, this.login.token, id, this.login.id).then(
              (response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.getDataTable()
                  : console.log(response.message);
              }
            );
          }
        });
    },
    getDataSurvey() {
      this.survey_loading = true;
      this.survey_file = false;

      show(
        "rha/visit/survey/response/survey",
        this.login.token,
        this.rha_id
      ).then((response) => {
        this.survey = response;

        if (!this.survey) {
          this.defaultDataSurvey();
        } else if (
          (this.survey.id &&
            this.survey.url &&
            this.survey.survey_stadistic_id === 1) ||
          this.survey.survey_stadistic_id === 6
        ) {
          this.survey_file = true;
        }

        this.survey_loading = false;
      });
    },
    defaultDataSurvey() {
      this.survey = {
        id: null,
        document_file_0: null,
        created_by_id: this.login.id,
        updated_by_id: this.login.id,
        rha_id: this.rha_id,
        survey_stadistic_id: null,
        survey_type_id: null,
      };
    },
    formAddEditSurvey() {
      if (!this.survey.id) {
        this.defaultDataSurvey();
      }
      this.survey_dialog = true;
    },
    formDialogCloseSurvey() {
      this.survey_dialog = false;

      if (!this.survey.id) {
        this.defaultDataSurvey();
      } else {
        this.getDataSurvey();
      }

      if (
        !this.survey.id ||
        (this.survey.id && this.survey.survey_stadistic_id !== 6)
      ) {
        this.$refs.form_submit_survey.resetValidation();
      }
    },
    formSubmitSurvey() {
      if (this.$refs.form_submit_survey.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${
                this.survey.id ? "creación" : "edición"
              } del registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              storeUpdate(
                "rha/visit/survey/response",
                this.login.token,
                this.survey.id,
                this.survey,
                this.login.id
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                if (response.success) {
                  this.survey_dialog = false;
                  this.getDataSurvey();
                  this.loading = false;
                } else {
                  console.log(response);
                }
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    id_base64() {
      return btoa(this.rha_id);
    },
    get_server_name() {
      return window.location.origin;
    },
    copy() {
      var codigoACopiar = document.getElementById("link");
      var seleccion = document.createRange();
      seleccion.selectNodeContents(codigoACopiar);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(seleccion);
      var res = document.execCommand("copy");
      window.getSelection().removeRange(seleccion);

      this.$swal.fire(
        msgAlert("success", "URL de encuesta digital copiado en portapapeles")
      );
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    this.getDataTable();

    catalog("survey_stadistics", this.login.token).then((response) => {
      this.survey_stadistics = response;
      this.survey_stadistics_loading = false;
    });
    catalog("survey_types", this.login.token).then((response) => {
      this.survey_types = response;
      this.survey_types_loading = false;
    });

    this.getDataSurvey();
  },
};
</script>

<style>
.text-description {
  white-space: pre-line;
}
</style>